import React from 'react';

import get from 'lodash/get';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { Hero } from 'components/organisms';
import { getPageEyebrow } from 'utils/get-page-eyebrow';
import { ModuleRenderer } from 'utils/module-renderer';
import { PageDataLoader, fetchPageData } from 'utils/page-data-loader';
import { VerifyPageContext } from 'utils/verify-page-context';
import { QUERY_TYPES } from 'constants/query';

import { getFilteredComponents } from 'utils/get-filtered-components';

/**
 * Renders the Landing Page template.
 * @param {Object} pageData The Contentful data object.
 */
const LandingPage = ({ pageData }) => {
  const {
    modulesCollection: { items: components },
    __typename: pageContext,
    hero,
  } = pageData;
  const verifiedContext = VerifyPageContext(pageContext);
  const filteredComponents = getFilteredComponents(components);
  return (
    <>
      <article>
        {/* Hero */}
        {hero && (
          <Hero
            key={get(hero, 'sys.id')}
            hero={hero}
            hasLowerNav={!!pageData.lowerNavigation}
            eyebrow={getPageEyebrow(pageData)}
          />
        )}
        {/* Modules */}
        <section>
          <ModuleRenderer
            components={filteredComponents}
            pageContext={verifiedContext}
          />
        </section>
      </article>
    </>
  );
};

export default function LandingPageWithData({
  navigationData,
  navCollectionName,
  footerData,
  footerCollectionName,
  pageData,
  pageError,
  pageLoading,
  componentId,
}) {
  return (
    <PageDataLoader
      WrappedComponent={LandingPage}
      componentId={componentId}
      navigationData={navigationData}
      navCollectionName={navCollectionName}
      footerData={footerData}
      footerCollectionName={footerCollectionName}
      pageData={pageData}
      pageError={pageError}
      pageLoading={pageLoading}
    />
  );
}

export async function getServerSideProps(context) {
  const {
    locale,
    query: { tag, slug },
  } = context;

  const data = await fetchPageData({
    locale,
    tag,
    slug,
    pageSlug: false,
    queryType: QUERY_TYPES.PAGE_LANDING_QUERY,
  });

  return {
    props: {
      ...data,
      ...(await serverSideTranslations(locale, ['common'])),
      componentId: `${locale}-${tag}-${slug}`,
    },
  };
}
